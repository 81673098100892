.nav__menu {
  display: inline-block;
  font-size: 22px;
  line-height: 14px;
  font-weight: bold;
  cursor: pointer;
  padding-right: 22 px;
}
@media only screen and (max-width: 1020px) {
  .nav__menu {
    display: none;
  }
}

.menu__item {
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
}

.currency {
  height: 60px;
  border: 1px solid black;
  margin-bottom: 15px;
}

.line {
  display: flex;
  margin-right: 10px;
  align-items: center;
  width: fit-content;
  min-width: 1100px;
  justify-content: flex-start;
  margin-top: 20px;
  background: white;
  border-radius: 15px;
  border: 1px solid blue;
}
@media only screen and (max-width: 400px) {
  .line {
    width: fit-content;
    min-width: 355px;
  }
}
@media only screen and (max-width: 1250px) {
  .line {
    min-width: 240%;
  }
}
row {
  margin-top: 300px !important;
}
p {
  display: block;
  text-align: left;
  width: 68%;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 1830px) {
  p {
    width: 60%;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 1655px) {
  p {
    width: 55%;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 1250px) {
  p {
    width: 0;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
img.house {
  width: 100%;
  border: 0;
  border-top-color: initial;
  border-top-style: initial;
  border-top-width: 0px;
  border-right-color: initial;
  border-right-style: initial;
  border-right-width: 0px;
  border-bottom-color: initial;
  border-bottom-style: initial;
  border-bottom-width: 0px;
  border-left-color: initial;
  border-left-style: initial;
  border-left-width: 0px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}
img.icon {
  height: 100%;
  padding: 10px 10px;
}
@media only screen and (max-width: 900px) {
  img.icon {
    height: auto;
  }
}

.contain__main {
}
.column__right {
  /* width: fit-content; */
  text-align: left;
  margin-left: 10px;
  width: 41% !important;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 600px) {
    width: fit-content;
  }
}
.container {
  display: block;
  text-align: center;
  width: 100% !important;
}
.free__calc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 100px 45px;
}

.row__custom {
  display: block;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0px;
  height: auto;
  justify-content: space-between;
  @media (max-width: 1140px) {
    min-width: 505px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 56px;
    height: auto;
    justify-content: space-between;
  } */
}
.options__div {
  /* padding: 75px 0px 0px 0px; */
  flex-direction: 42%;
}

.container__img {
  width: 58%;
}

.main {
  color: black;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  letter-spacing: 0em;
  line-height: 1.2;
  font-weight: bold;
  text-transform: none;
  display: block;
  font-size: 45px;
  padding: 0px 0px;
}
.main__variants {
  border-top-style: solid;
  padding: 35px 5px 0px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  letter-spacing: 0em;
  line-height: 1.2;
  font-weight: 400;
  text-transform: none;
  display: block;
  color: white;
  background: black;
  font-style: normal;
  font-size: 1.8rem;
}
.main__calc {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  min-width: 200px;
  @media (max-width: 700px) {
    float: center;
    min-width: 505px !important;
  }
}
.callme__img {
  padding: 0px 0px;
  max-width: 120px;
}

.main__img {
  float: left;
  /* display: block; */
  width: 100%;
  /* min-width: 280px;
  height: auto;
  overflow: hidden; */
}

.img__options {
  padding: 0px 10px;
}

.main__options {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 50%;
  text-align: justify;
  align-items: center;
  height: fit-content;
  font-weight: 600;
  color: white;
  font-size: 17px;
  border-radius: 10px;
  padding: 15px 20px;
  text-decoration: none;
  text-shadow: 1px 1px 5px black;
  background: #16151a;
  margin-bottom: 8px;
  justify-content: space-between;
}
.get__help {
  font-size: 1.8rem;
  text-align: center;
  color: black;
  font-weight: bold;
}

.footer__container {
  background: black;
}

.footer__sub {
  font-size: 1.2rem;
  color: white;
  margin: 0px 0px 10px;
}

.square-holder {
  padding: 30px;
  align-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background: radial-gradient(circle, rgb(0 0 0 / 15%) 10%, #142c47 55%);
}

.square-holder img {
  max-width: 100%;

  transition: all 0.3s;
}
.square-holder:hover img {
  filter: contrast(180%);
}

.spacer {
  aspect-ratio: 960/320;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer__header {
  background-image: url("./layerheader.svg");
  aspect-ratio: 960/360;
  width: 100%;
  height: 176px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0px 0px 0px 0px;
}

.layer__home {
  background-image: url("./layerhome.svg");
}

/* .layer__wave {
  background-image: url("./wave-haikei.svg");
  aspect-ratio: 11/1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

@media only screen and (max-width: 540px) {
  .layer__home {
    background-image: none;
    background: white;
  }
  .main__img {
    float: center;
  }
  .layer__header {
    background-image: none;
    background: white;
    height: 60px;
  }
}
@media only screen and (max-width: 580px) {
  .main__img {
    margin: 0px 0px 0px 50px;
  }
}

/* .calculator__main {
  background: #8383c4;
  aspect-ratio: 960/360;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../svg/layer4.svg");
} */

.item {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.willCall {
  font-size: 19.2px;
  word-spacing: 3px;
  color: black;
  text-align: center;
  margin-top: 28px;
}
@media (max-width: 600px) {
  font-size: 16px;
  line-height: 22px;
}
