@import url("https://fonts.googleapis.com/css?family=Karla");

.calculator {
}

.body {
  font-family: "Montserrat", sans-serif;
  margin: 24px;
}

.text-center {
  text-align: center;
  font-size: 4rem;
}

.h1,
h2 {
  margin-top: 0;
}

.h1 {
  margin-bottom: 24px;
}

.h2 {
  margin-bottom: 16px;
}

.section {
  margin-bottom: 24px;
}

.input {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.hr {
  margin: 24px 0;
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.label {
  display: block;
  margin-bottom: 16px;
}

.button[type="button"] {
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table {
  width: 75%;
  color: #212529;
  border-collapse: collapse;
}

.table th,
table td {
  text-align: inherit;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  vertical-align: top;
}

.table th {
  background-color: #e9ecef;
}

.table thead th {
  vertical-align: bottom;
}

.annualCompoundCalculator {
  background: white;
  min-width: fit-content;
  max-width: fit-content;
  margin: auto;
  padding: 15px;
}
