$optionDefaultColours: #ed5565, #fc6e51, #ffce54, #2ecc71, #5d9cec, #ac92ec;

.placeholder {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white !important;
  margin: 10px 10px 10px 10px;
  cursor: pointer !important;
  opacity: 1 !important;
  // height: 100vh;
  @media screen and (max-width: 450px) {
    min-width: 383px !important;
  }
  font-family: "Roboto", sans-serif;
}
.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;

  color: #000;
}
.options {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;

  min-width: 595px;
  max-width: 950px;
  width: calc(100% - 100px);

  height: 700px;

  @for $i from 1 through 4 {
    @media screen and (max-width: 450px) {
      margin: 0px 0px 0px 0px;
      height: 500px;
      min-width: auto;
      max-width: auto;
      width: -webkit-fill-available;
      .option:nth-child(#{3-$i}) {
        display: none;
      }
    }
  }

  .option {
    position: relative;
    overflow: hidden;

    min-width: 70px;
    margin: 10px;
    //border:0px solid --defaultColor;

    background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
    background-size: auto 120%;
    background-position: center;

    cursor: pointer;

    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    @media screen and (max-width: 450px) {
      min-width: 20px !important;
      max-width: 75px !important;
    }
    @for $i from 1 through length($optionDefaultColours) {
      &:nth-child(#{$i}) {
        cursor: pointer;
      }
    }
    &.active {
      flex-grow: 10000;
      transform: scale(1);

      max-width: 700px;
      margin: 0px;
      border-radius: 40px;

      background-size: auto 100%;
      @media screen and (max-width: 450px) {
        min-width: 200px !important;
        max-width: 205px !important;
      }
      .shadow {
        box-shadow: inset 0 -120px 120px -120px black,
          inset 0 -120px 120px -100px black;
      }
      .label {
        bottom: 20px;
        left: 20px;
        .info > div {
          left: 0px;
          opacity: 1;
        }
      }
      &:active {
        transform: scale(0.9);
      }
    }
    &:not(.active) {
      flex-grow: 1;

      border-radius: 30px;
      .shadow {
        bottom: -40px;
        box-shadow: inset 0 -120px 0px -120px black,
          inset 0 -120px 0px -100px black;
      }
      .label {
        bottom: 10px;
        left: 10px;
        .info > div {
          left: 20px;
          opacity: 0;
        }
      }
    }
    .shadow {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;

      height: 120px;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }
    .label {
      display: flex;
      position: absolute;
      right: 0px;

      height: 40px;
      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-width: 50px;
        max-width: 50px;
        height: 50px;
        border-radius: 100%;

        background-color: white;
        color: var(--defaultBackground);
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-left: 10px;
        margin-top: 15px;
        color: white;

        white-space: pre;
        > div {
          position: relative;

          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95),
            opacity 0.5s ease-out;
        }
        .main {
          font-weight: 900;
          font-size: 2.4rem;
          color: white;
          text-shadow: 0px 0px 10px #000000, 0px 0px 10px #000000 !important;
          @media screen and (max-width: 450px) {
            font-size: 1.15rem;
          }
        }
        .sub {
          transition-delay: 0.1s;
          color: black;
          font-weight: 700;
          font-size: 1.85rem;
          color: white;
          text-shadow: 0px 0px 10px #000000, 0px 0px 10px #000000 !important;
          @media screen and (max-width: 450px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
