#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn {
  display: none;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  z-index: 101;
}
@media only screen and (max-width: 1020px) {
  .menu__btn {
    display: flex;
  }
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: row;
  position: absolute;
  /* position: fixed; */
  visibility: hidden;
  top: 0;
  z-index: 100;
  left: -100%;
  border: 1px solid black;
  width: fit-content;

  padding: 50px 0px 0px;

  list-style: none;

  background-color: #eceff1;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);

  transition-duration: 0.25s;
}

.menu__item {
  display: inline-flex;
  padding: 10px 20px;

  color: #333;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;

  text-decoration: none;

  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #cfd8dc;
}

@media screen and (max-width: 500px) {
  .menu__item {
    display: block;
    padding: 13px 13px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .menu__item {
    padding: 10px 12px;
    font-size: 17px;
  }
}
@media screen and (max-width: 1500px) {
  .menu__item {
    padding: 10px 12px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1350px) {
  .menu__item {
    padding: 10px 7px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .menu__item {
    padding: 10px 3px;
    font-size: 13px;
  }
}
