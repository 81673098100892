.owl-carousel {
  width: 100%;
  height: 75vh;
}

.button {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px 8px;
  border: none;
  font-family: "Montserrat", sans-serif;
  background: white;
  color: black;
  text-decoration: none;
}

.slide {
  width: 100%;
  height: 75vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.slide-1 {
  background-image: url("../../images/zinc1.jpg");
}

.slide-2 {
  background-image: url("../../images/metal1.jpg");
}

.slide-3 {
  background-image: url("../../images/zinc3.jpg");
}

.slide-content {
  text-align: center;
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  padding: 0px 0px%;
}
@media only screen and (max-width: 555px) {
  .owl-carousel {
    width: 100%;
    height: 50vh;
  }

  .slide {
    width: 100%;
    height: 50vh;
  }
  .slide-content {
    padding: 0 20%;
    top: 50%;
  }
  .slide-content h1 {
    font-size: 1.3rem !important;
  }
  .slide-content p {
    font-size: 0.9rem !important;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
  }
  .slide-content button {
    font-size: 1.2rem !important;
    padding: 3px 3px;
  }
}

.slide-content h1 {
  text-shadow: 1px 1px 1px black;
  font-size: 2.9rem;
  font-weight: 700;
  text-transform: uppercase;
}

.slide-content p {
  text-shadow: 1px 1px 1px black;
  font-size: 1.45rem;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.slide-content button {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px 8px;
  border: none;
  font-family: "Montserrat", sans-serif;
}

.owl-dots {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1%;
}

.owl-dots span {
  width: 20px !important;
  height: 20px !important;
}

.owl-dots button {
  border: none !important;
  outline: none !important;
}

.owl-nav button {
  border: none !important;
  outline: none !important;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white !important;
  font-size: 65px !important;
  font-weight: bolder !important;
  background: none !important;
}

.owl-prev {
  left: 1%;
}

.owl-next {
  right: 1%;
}
